<template>
<div class="common-bg-member">
  <div class="w700 common-padding-120">
    <h1>
      登錄
    </h1>
    <div class="m-form-item">
      <p>電郵</p>
      <el-input v-model="email"  class="m-input"  @keydown.enter="login" />
    </div>
    <div class="m-form-item">
      <p>密碼</p>
      <el-input v-model="password"  type="password" class="m-input"  @keydown.enter="login" />
    </div>

    <div class="forget">
      <router-link to="/changePassword">
        忘記密碼？
      </router-link>
    </div>

    <div class="m-btn">
      <button class="m-btn-common" @click="login">立即登錄</button>
      <div class="m-btn-common m-btn-common-white">
        <router-link to="/register">
          沒有賬號？立即註冊
        </router-link>
      </div>
    </div>

  </div>
</div>


  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>

</template>

<script>
import {loginPost} from "@/network/main";
export default {
  name: "Login",
  data(){
    return{
      email:'',
      password:'',
      isPopupVisible:false,
      popupText:'',
    }
  },
  methods:{
    login(){
      const formData = new FormData();
      formData.append('email', this.email);
      formData.append('password', this.password);
      loginPost(formData).then((res)=>{
        if (res.data.code ==200){
          this.isPopupVisible = true;
          this.popupText = "登錄成功！";
          this.$router.push('/member');
          setTimeout(() => {
            this.isPopupVisible = false;
            location.reload();
          }, 500)
          localStorage.setItem('token', res.data.data.token);
          console.log(res.data.data.token)
        }else {
          this.isPopupVisible = true
          this.popupText = res.data.message
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000)
        }
      })
    },
  }
}
</script>

<style scoped>
.m-btn-common-white{
  height: 48px;
  line-height: 48px;
  border: 1px solid transparent;
  padding: 0 22px;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 17px;
  border-radius: 5px;
  cursor: pointer;
}
</style>